<template>
  <div class="single-bridge-common single-bridge-archive">
    <div class="p-head flex space-between">
      <div class="crumb-bj">
        <div class="crumb" v-for="(item,i) in crumbList" :key="i" @click="crumb(item,i)">
         <i class="el-icon-arrow-right" v-if="i !== 0"></i>{{item.name}}
        </div>
      </div>
      <div class="mb-1 flex">
        <el-upload
            class="upload-demo"
            :headers="headers"
            :action="uploadUrl"
            :data="uploadParams"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess">
          <el-button type="primary">上传文件</el-button>
        </el-upload>
        <el-button class="btn-primary-plain" @click="add()">新建文件夹</el-button>
      </div>
    </div>
    <div class="archive-sub-title flex flex-end">
      <span class="title-right">共{{folderList.length}}个文件</span>
    </div>
    <div class="folder-wrapper">
      <ul class="manage-scroll folder-list">
        <li v-for="(item, index) in folderList" :key="index" @click="active = index" @dblclick="openChild(item)" :class="active === index ? 'active' : ''">
          <i class="el-icon-error list-icon" v-if="active === index" @click="remove(item.id)"></i>
          <img v-if="item.type === 1" src="../../../../../assets/images/text.png" alt="" />
          <img v-else src="../../../../../assets/images/folder.png" alt="" />
          <el-input size="mini" v-model="item.name" placeholder="" @change="update(item)" v-if="active === index"></el-input>
          <p class="ellipsis" :title="item.name" v-else>{{ item.name }}</p>
        </li>
      </ul>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="新建文件夹"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        width="500px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form
            :model="form"
            :rules="form.formRules"
            ref="ruleForm"
            label-width="80px"
            class="demo-ruleForm"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import common from '../../../../../assets/js/common'
export default {
  data() {
    return {
      crumbList:[{id:0,name:'我的列表'}],
      checked: false,
      active:'',
      dialogVisible:false,
      uploadParams:{parentId:''},
      uploadUrl: localStorage.getItem('baseUrl') + '/archivesManage/upload',
      headers: {
        token: localStorage.getItem('bridge-token'),
      },
      form:{
        name:'',
        formRules: {
          name: [
            { required: true, message: '请填写名称', trigger: 'blur' }
          ]
        },
      },
      folderList: [],
      id:0
    };
  },
  created() {
    this.initData()
  },
  methods:{
    crumb(data,i){
      this.id = data.id
      this.initData()
      this.crumbList.splice(i + 1,this.crumbList.length - i + 1)
    },
    initData(){
      this.$http.get('/archivesManage/currentList',{params:{id:this.id}}).then(res => {
        if (res.success){
          this.folderList = res.data
          this.active = ''
        }
      })
    },
    openChild(data){
      if (data.type === 0){
        this.id = data.id
        this.initData()
        this.crumbList.push(data)
      } else {
        this.$confirm('确定下载此文件?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.get('/archivesManage/download', {
            contentType:"application/x-www-form-urlencoded",
            responseType: "blob",
            params: {id:data.id}
          }).then(res => {
            common.blobDownLoad(res)
          })
        }).catch(() => {});
      }
    },
    add(){
      this.form.name = ''
      this.dialogVisible = true
    },
    update(data){
      this.$http.post('/archivesManage/rename',{id:data.id,name:data.name,type:data.type}).then(res => {
        if (res.success){
          this.initData()
          this.active = ''
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    remove(id){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.get('/archivesManage/remove',{params:{id:id}}).then(res => {
          if (res.success){
            this.initData()
            this.$message.success('删除成功')
          }
        })
      }).catch(() => {});
    },
    beforeUpload(){
      this.uploadParams.parentId = this.id
    },
    // 上传成功事件
    uploadSuccess(res) {
      this.initData()
    },
    submit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$http.post('/archivesManage/createFolder',{parentId:this.id,name:this.form.name}).then(res => {
            if (res.success){
              this.initData()
              this.dialogVisible = false;
              this.active = ''
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    resetFields() {
      this.dialogVisible = false;
      this.$refs.ruleForm.resetFields();
    },
    beforeDialogClose(done) {
      this.$refs.ruleForm.resetFields();
      done();
    },
  }
};
</script>

<style scoped>
  .single-bridge-archive .folder-wrapper .folder-list > li{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 125px;
    padding: 10px;
  }
  .single-bridge-archive .folder-wrapper .folder-list > li > p{
    width: 100%;
  }
  .active{
    background: #ecf5ff;
    border: 1px solid #b3d8ff;
    border-radius: 2px;
  }
  .list-icon{
    font-size: 20px;
    color: red;
    position: absolute;
    right: -10px;
    top: -10px;
  }
  .btn-primary-plain{
    margin-left: 10px;
  }
  .single-bridge-archive .archive-sub-title{
    padding-right: 0;
  }
  .crumb-bj{
    width: calc(100% - 230px);
    height: 40px;
    line-height: 40px;
    border: 1px solid #efefef;
    padding: 0 10px;
    display: flex;
  }
  .crumb:hover{
    cursor: pointer;
    color: #409EFF;
  }
  .mb-1{
    width: 220px;
  }
</style>
